import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import styled from "styled-components";
import {
  mainBlack,
  mainWhite,
  innerWidth,
  lightGrey,
  screen,
} from "../components/common/variables";
import SEO from "../components/seo";
import SubBanner from "../components/banner/sub";
import ReactPlayer from "react-player";
import Quote from "../components/quote";
import Form from "../components/form";
import BackgroundImage from "../components/background-image";

const Wrapper = styled.div`
  .banner {
    position: relative;
    background: ${lightGrey};
    border-top: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
      border-bottom: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      .featured-txt {
        text-align: center;
        @media ${screen.small} {
          text-align: left;
        }

        .inner-txt {
          .capital-heading {
            margin: 12px 0 0 0;
            @media ${screen.xsmall} {
              margin: 25px 0 0 0;
            }
            @media ${screen.large} {
              max-width: 540px;
            }
          }

          .normal-p {
            max-width: 540px;
            margin: 25px 0 0 0;
          }
        }
      }
    }

    .bg {
      order: -1;
      @media ${screen.small} {
        order: 0;
      }
    }
  }

  .video {
    background: ${mainWhite};

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }
    }

    .capital-subheading {
      margin: 0 0 22px 0;
      text-align: center;
      @media ${screen.xsmall} {
        margin: 0 0 30px 0;
      }

      @media ${screen.large} {
        margin: 0 0 35px 0;
      }
    }

    .iframe-wrapper {
      max-width: 1200px;
      margin: 0 auto;

      .player {
        position: relative;
        padding-top: 56.25%;
        .react-player {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .quote {
    background: ${lightGrey};

    .msg {
      color: ${mainBlack};
      max-width: 1100px;
    }
  }

  .bottom-images {
    border-top: 22px ${mainWhite} solid;
    border-bottom: 22px ${mainWhite} solid;
    display: flex;
    flex-direction: column;
    @media ${screen.xsmall} {
      border-top: 15px ${mainWhite} solid;
      border-bottom: 15px ${mainWhite} solid;
    }

    @media ${screen.large} {
      border-top: 17px ${mainWhite} solid;
      border-bottom: 17px ${mainWhite} solid;
    }
    @media ${screen.xsmall} {
      flex-direction: row;
    }

    .col {
      height: 200px;
      @media ${screen.xsmall} {
        width: 50%;
        height: calc(300px + 7vw);
      }

      &--right {
        border-top: 8px ${mainWhite} solid;
        @media ${screen.xsmall} {
          border-top: 0;
        }
      }
    }
  }
`;

const BookTheChoirPage = ({ data }) => {
  const {
    banner_heading,
    banner_description,
    banner_image,
    bottom_image_one,
    bottom_image_two,
    meta_description,
    quote_message,
    title_tag,
    video_full_url,
    video_heading,
  } = data.content.data;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <SubBanner className="banner" bgUrl={banner_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <p className="red-heading">GET INVOLVED</p>
                <h1 className="capital-heading">{banner_heading.text}</h1>
                <p className="normal-p">{banner_description}</p>
              </div>
            </div>
          </div>
        </SubBanner>

        <section className="video">
          <div className="inner-wrapper">
            <h3 className="capital-subheading">{video_heading.text}</h3>

            <div className="iframe-wrapper">
              <div className="player">
                <ReactPlayer
                  className="react-player"
                  url={video_full_url.embed_url}
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </section>

        <Quote className="quote" message={quote_message} />

        <section className="bottom-images">
          <BackgroundImage className="col" bgUrl={bottom_image_one.url} />

          <BackgroundImage
            className="col col--right"
            bgUrl={bottom_image_two.url}
          />
        </section>

        <Form heading={"Book the choir"} booking />
      </Wrapper>
    </Layout>
  );
};

export default BookTheChoirPage;

export const dataQuery = graphql`
  {
    content: prismicBookTheChoirPage {
      data {
        banner_heading {
          text
        }
        banner_image {
          alt
          url
        }
        banner_description
        video_heading {
          text
        }
        video_full_url {
          embed_url
        }
        quote_message
        title_tag
        meta_description
        bottom_image_one {
          alt
          url
        }
        bottom_image_two {
          alt
          url
        }
      }
    }
  }
`;
